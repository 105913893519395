
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n/index";
import ApiService from "@/core/services/ApiService";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "update-agent",
  emits: ["refresh"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    historyId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    //Create form validation object
    const rules = Yup.object().shape({
      notes: Yup.string().required(),
    });

    const onUpdateMemo = async (values: any) => {
      await ApiService.patch(`/al/history/${props.historyId}/note`, {
        notes: values.notes,
      })
        .then(() => {
          Swal.fire(
            t("Agents.Edit"),
            t("AlHistory.MemoEditSuccess"),
            "success"
          );
          const close = document.getElementById("kt_memo_update");
          if (close) close.click();
          emit("refresh");
        })
        .catch(() => {
          Swal.fire(t("Agents.Edit"), t("TryAgain"), "error");
        });
    };

    return {
      rules,
      onUpdateMemo,
    };
  },
});
