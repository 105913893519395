
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import moment from "moment";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import UpdateNote from "./UpdateNote.vue";

interface IAlHistory {
  pdate: string;
  memo: string;
  bettings: string;
  winamount: string;
  loseamount: string;
  historys: string;
  withdrawals: string;
  al_before: string;
  amount: string;
  al_after: string;
}

interface DateRange {
  start: string;
  end: string;
}

export default defineComponent({
  name: "al-history",
  components: {
    StatisticsWidget5,
    Datatable,
    DateRangePicker,
    UpdateNote,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    const tableHeader = ref([
      {
        name: t("Deposit.Date"),
        key: "pdate",
        text: true,
        sortable: true,
      },
      {
        name: t("Deposit.Type"),
        key: "memo",
        customslot: true,
      },
      {
        name: t("AlHistory.Agent"),
        key: "subagent",
        text: true,
      },
      {
        name: t("BetSummary.BetAmount"),
        key: "bettings",
        sortable: true,
        currency: true,
      },
      {
        name: t("BetSummary.WinAmount"),
        key: "winamount",
        sortable: true,
        currency: true,
      },
      {
        name: t("AlHistory.Before"),
        key: "al_before",
        currency: true,
      },
      {
        name: t("AlHistory.Usage"),
        key: "amount",
        customslot: true,
      },
      {
        name: t("AlHistory.After"),
        key: "al_after",
        currency: true,
      },
      {
        name: t("AlHistory.Memo"),
        key: "notes",
        customslot: true,
      },
    ]);

    const search = ref({
      type: "ALL",
      agent: "ALL",
    });

    const types = [
      { value: "ALL", label: t("MoneyTransfer.All") },
      { value: "SETTLEMENT", label: t("AlHistory.Settlement") },
      { value: "SUB_ADD", label: t("Deposit.SUB_ADD") },
      { value: "SUB_DEDUCT", label: t("Deposit.SUB_DEDUCT") },
    ];

    const subagents = ref([{ value: "ALL", label: t("MoneyTransfer.All") }]);

    /**
     * Daterange Filter
     *
     */
    let daterange = {
      start: moment().startOf("month").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };

    const setSelectedDate = (date: DateRange) => {
      daterange = date;
      getAlHistories();
    };

    const tableData = ref<Array<IAlHistory>>([]);
    const totalUsage = ref<number>(0);

    /**
     * Get Al Histories
     *
     */
    const getAlHistories = async () => {
      const results = await ApiService.get(
        `/al/histories?type=${search.value.type}&subagent=${search.value.agent}&start=${daterange.start}&end=${daterange.end}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
      if (results.length > 0)
        totalUsage.value = results
          .map((item) => Number(item.amount))
          .reduce((prev, next) => Number(prev) + Number(next));
    };

    /**
     * Get SubAgents
     *
     */
    const getSubAgents = async () => {
      const subs = await ApiService.get("/agent/subagent/list")
        .then((res) => res.data)
        .catch(() => []);

      if (subs.length > 0) {
        subs.forEach((sub) =>
          subagents.value.push({
            value: sub.id,
            label: `${sub.name} (${sub.code})`,
          })
        );
      }
    };

    /**
     * Set Selected History
     *
     */
    const selectedHistory = ref("");
    const onSelectHistory = (historyId: string) =>
      (selectedHistory.value = historyId);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.AlHistory"), [t("Menu.AlManage")]);
      getSubAgents();
    });
    return {
      tableData,
      tableHeader,
      types,
      subagents,
      search,
      totalUsage,
      getAlHistories,
      setSelectedDate,
      selectedHistory,
      onSelectHistory,
    };
  },
});
